import React from "react";
import { toast } from "react-toastify";
import { Col, Row } from "reactstrap";
import { getUserInfo } from "../../../services/auth";
import SubmitButton from "../../atoms/submitButton";
import FormContainer from "../../atoms/formContainer";
import Hr from "../../atoms/hr";
import Label from "../../atoms/label";
import LocalDate from "../../atoms/localDate";
import FormFieldQuestion from "../../atoms/formFieldQuestion";
import FormFieldRadioQuestion from "../../atoms/formFieldRadioQuestion";
import {
  GetManagerIncidentCloseForm,
  SubmitManagerIncidentCloseForm,
} from "../../../services/incidentReport";
import FormFieldUploadFile from "../../molecules/uploadFile";
import { ChangeTypes } from "../../../data/enums";
import {
  faBell,
  faCheckCircle,
  faCircleCheck,
  faImage,
  faListCheck,
  faPersonCircleCheck,
} from "@fortawesome/free-solid-svg-icons";
import FormHeader from "../../atoms/formHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Center from "../../atoms/center";
import Input from "../../atoms/input";
import AdjustButton from "../../atoms/adjustButton";
import Required from "../../atoms/required";
import FormFieldType from "../../atoms/formFieldType";
import FormHeaderTitle from "../../atoms/formHeaderTitle";
import IRCorrectiveActions from "./correctiveActions";

/* eslint-disable */

const initialState = {
  ID: null,
  IncidentGUID: "",
  ActionTakenProtect: "",
  ActionTakenSafe: "",
  ConfirmIncidentAddressed: null,
  CorrectiveActionTaken: "",
  numberOfCorrectiveActions: 0,
  searchTerm: "",
  CompletedBy: "",
  CAPANeeded: false,
  CompletedOn: "",
  CompletedByName: "",
  submitting: false,
  formValid: true,
  searchError: false,
  paired: false,
  EstimatedLostDays: "",
  EstimatedRestrictedDays: "",
  EstimatedTransferDays: "",
  ModifiedBy: "",
  KeyLearnings: "",
  AdditionalEmail: "",
  isInjury: false,
};

export default class ManagerIncidentClose extends React.Component {
  constructor(props) {
    super(props);

    this.child = React.createRef();
    this.state = initialState;

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleUserInput = this.handleUserInput.bind(this);
    this.validateForm = this.validateForm.bind(this);
  }

  componentDidMount = () => {
    this.setState(
      {
        searchTerm: this.props.guid,
        CompletedBy: getUserInfo().Email,
        paired: this.props.disable && !this.props.isEHS,
        isInjury: this.props.typeName[0].key === "Injury/Illness",
      },
      () => {
        if (this.state.searchTerm !== "") {
          this.serialNumberSearch();
        }
      }
    );
  };

  clear = () => {
    this.setState(initialState);
    if (
      typeof window !== "undefined" &&
      document?.getElementsByTagName("form")[0]
    ) {
      document?.getElementsByTagName("form")[0].removeAttribute("class");
    }
  };
  updateCorrectiveActions = (items) => {};

  submitForm(draft = true) {
    let newState = JSON.parse(JSON.stringify(this.state));
    SubmitManagerIncidentCloseForm({ formData: newState })
      .then((res) => {
        if (res.data.HasErrors) {
          res.data.ErrorList.forEach((item) => {
            toast.error(item);
          });
          this.setState({
            submitting: false,
          });
        } else {
          if (this.props.capa) {
            toast.success("Safety Alert has been completed");
          } else {
            toast.success("Ticket has been closed successfully");
          }

          this.setState({
            searchTerm: this.state.IncidentGUID,
            submitting: false,
          });
          this.props.updateTab(4);
        }
      })
      .catch((error) => {
        toast.error("There was an error submitting the form.");
        this.setState({ submitting: false });
      });
  }

  serialNumberSearch = (search = "", showMessage = true) => {
    GetManagerIncidentCloseForm(search !== "" ? search : this.state.searchTerm)
      .then((res) => {
        if (typeof res === "string") {
          toast.error(res);
          return;
        }
        if (res.HasErrors) {
          res.ErrorList.forEach((item) => {
            toast.error(item);
          });
        } else {
          if (res.ManagerIncidentCloseForm) {
            this.setState(
              {
                ...res.ManagerIncidentCloseForm,
                paired: !this.props.isEHS,
              },
              () => {
                if (showMessage) {
                }
                if (typeof window !== "undefined") {
                  window.scrollTo(0, 0);
                }
              }
            );
          } else {
            this.setState({
              IncidentGUID: res.IncidentForm.GUID,
            });
          }
        }
      })
      .catch((err) => {
        toast.error("Error");
      });
  };

  validateForm = () => {
    let isValid = true;

    if (isValid) {
      this.submitForm(false);
    } else {
      this.setState({ submitting: false });
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState(
      {
        submitting: true,
        ModifiedBy: getUserInfo().Email,
      },
      () => {
        this.validateForm();
      }
    );
  };

  handleUserInputArea = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({ [name]: value });
  };

  buttonAdjust = (value, field, isIncrease) => {
    if (isNaN(parseInt(value))) {
      value = 0;
    } else {
      value = parseInt(value);
    }
    let newVal = isIncrease ? value + 1 : value - 1;
    if (newVal >= 0 && newVal <= 1000) {
      this.setState({ [field]: newVal });
    }
  };

  handleUserInput = (event, key = ChangeTypes.NONE) => {
    const name = event.target.name;
    const value = event.target.value;

    switch (key) {
      case ChangeTypes.UPPER:
        this.setState({ [name]: value.toUpperCase().trim() });
        break;
      case ChangeTypes.NONE:
        this.setState({ [name]: value.trim() });
        break;
      case ChangeTypes.CHECK:
        this.setState({ [name]: value === "yes" });
        break;
      case ChangeTypes.NOTES:
      default:
        this.setState({ [name]: value });
        break;
    }
  };
  updateState = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  handleCheckChange = (event) => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  handleKeyPress = (e) => {
    if (e.key === "Enter") {
      if (document.activeElement.name == "searchTerm") {
        this.serialNumberSearch();
      }
    }
  };

  render() {
    return (
      <FormContainer>
        <FormHeaderTitle>
          <FontAwesomeIcon
            icon={this.props.capa ? faBell : faPersonCircleCheck} 
          ></FontAwesomeIcon>
          &nbsp;
          {this.props.capa && <>Safety Alert </>}
          {!this.props.capa && <>Manager Closeout</>}
          {this.props.typeName?.length > 0 && (
            <FormFieldType lg={true}>
              {this.props.typeName[0].key}
            </FormFieldType>
          )}
        </FormHeaderTitle>
        <Row>
          <Col>
            {!this.state.loading && (
              <form id="form" onSubmit={this.handleSubmit}>
                {this.props.capa && (
                  <>
                    {this.state.isInjury && (
                      <>
                        <Row style={{ marginBottom: "5px" }}>
                          <Col xs="12">
                            <Label>
                              Estimated Lost Days
                              <i style={{ fontWeight: "normal" }}>
                                - The number of days an employee is absent
                                because of a work-related illness or injury
                              </i>
                              <Required></Required>
                              <FormFieldType>Injury/Illness</FormFieldType>
                            </Label>
                          </Col>
                          <Col xs="12" sm="2" style={{ position: "relative" }}>
                            <Input
                              disabled={this.state.paired}
                              type="number"
                              value={this.state.EstimatedLostDays}
                              onChange={this.handleUserInput}
                              name="EstimatedLostDays"
                              required={true}
                            ></Input>
                            <AdjustButton
                              disabled={this.state.paired}
                              onUpClick={() =>
                                this.buttonAdjust(
                                  this.state.EstimatedLostDays,
                                  "EstimatedLostDays",
                                  true
                                )
                              }
                              onDownClick={() =>
                                this.buttonAdjust(
                                  this.state.EstimatedLostDays,
                                  "EstimatedLostDays",
                                  false
                                )
                              }
                            ></AdjustButton>
                          </Col>
                        </Row>
                        <Row style={{ marginBottom: "5px" }}>
                          <Col xs="12">
                            <Label>
                              Estimated restricted duty days (calendar){" "}
                              <i style={{ fontWeight: "normal" }}>
                                - The number of days an employee is placed under
                                work restrictions (e.g. no heavy lifting, must
                                sit while working, etc.)
                              </i>
                              <Required></Required>
                              <FormFieldType>Injury/Illness</FormFieldType>
                            </Label>
                          </Col>
                          <Col xs="12" sm="2" style={{ position: "relative" }}>
                            <Input
                              disabled={this.state.paired}
                              type="number"
                              value={this.state.EstimatedRestrictedDays}
                              onChange={this.handleUserInput}
                              name="EstimatedRestrictedDays"
                              required={true}
                            ></Input>
                            <AdjustButton
                              disabled={this.state.paired}
                              onUpClick={() =>
                                this.buttonAdjust(
                                  this.state.EstimatedRestrictedDays,
                                  "EstimatedRestrictedDays",
                                  true
                                )
                              }
                              onDownClick={() =>
                                this.buttonAdjust(
                                  this.state.EstimatedRestrictedDays,
                                  "EstimatedRestrictedDays",
                                  false
                                )
                              }
                            ></AdjustButton>
                          </Col>
                        </Row>
                        <Row style={{ marginBottom: "5px" }}>
                          <Col xs="12">
                            <Label>
                              Estimated job transfer days (calendar)
                              <i style={{ fontWeight: "normal" }}>
                                - The number of days an employee is transferred
                                to another job because they could not fulfill
                                their normal duties
                              </i>
                              <Required></Required>
                              <FormFieldType>Injury/Illness</FormFieldType>
                            </Label>
                          </Col>
                          <Col xs="12" sm="2" style={{ position: "relative" }}>
                            <Input
                              disabled={this.state.paired}
                              type="number"
                              value={this.state.EstimatedTransferDays}
                              onChange={this.handleUserInput}
                              name="EstimatedTransferDays"
                              required={true}
                            ></Input>
                            <AdjustButton
                              disabled={this.state.paired}
                              onUpClick={() =>
                                this.buttonAdjust(
                                  this.state.EstimatedTransferDays,
                                  "EstimatedTransferDays",
                                  true
                                )
                              }
                              onDownClick={() =>
                                this.buttonAdjust(
                                  this.state.EstimatedTransferDays,
                                  "EstimatedTransferDays",
                                  false
                                )
                              }
                            ></AdjustButton>
                          </Col>
                        </Row>
                        <FormFieldQuestion
                          readOnly={this.state.paired}
                          question="Immediate Action Taken to protect the injured employee"
                          value={this.state.ActionTakenProtect}
                          filter={"Injury/Illness"}
                          onChange={this.handleUserInputArea}
                          name="ActionTakenProtect"
                          maxLength="500"
                        ></FormFieldQuestion>
                      </>
                    )}
                  </>
                )}
                {!this.props.capa && (
                  <>
                    <FormFieldQuestion
                      readOnly={this.state.paired}
                      question="Immediate containment action taken to mitigate risk?"
                      filter={"Manager"}
                      value={this.state.ActionTakenSafe}
                      onChange={this.handleUserInputArea}
                      required={true}
                      name="ActionTakenSafe"
                      maxLength="500"
                    ></FormFieldQuestion>
                    <FormFieldQuestion
                      readOnly={this.state.paired}
                      question="Permanent corrective action to eliminate risk?"
                      filter={"Manager"}
                      value={this.state.KeyLearnings}
                      onChange={this.handleUserInputArea}
                      required={true}
                      name="KeyLearnings"
                      maxLength="500"
                    ></FormFieldQuestion>
                  </>
                )}
                {this.props.capa && (
                  <>
                    <FormHeader>
                      <FontAwesomeIcon icon={faListCheck}></FontAwesomeIcon>
                      &nbsp;Corrective / Preventive Actions
                    </FormHeader>
                    {this.state.IncidentGUID && (
                      <IRCorrectiveActions
                        disable={this.state.paired}
                        updateCA={this.updateCorrectiveActions}
                        guid={this.state.IncidentGUID}
                      ></IRCorrectiveActions>
                    )}
                  </>
                )}
                {!this.props.capa && (
                  <>
                    <FormFieldRadioQuestion
                      title="Confirm the incident has been addressed permanently?"
                      filter={"Manager"}
                      name="ConfirmIncidentAddressed"
                      readOnly={this.state.paired}
                      required={true}
                      value={this.state.ConfirmIncidentAddressed}
                      onChange={(event) =>
                        this.handleUserInput(event, ChangeTypes.CHECK)
                      }
                    />
                  </>
                )}
                <FormHeader>
                  <FontAwesomeIcon icon={faImage}></FontAwesomeIcon>
                  &nbsp;Attachments{" - "}
                  <span style={{ color: "#ba0000" }}>
                    (DO NOT UPLOAD MEDICAL FORMS)
                  </span>
                </FormHeader>
                <FormFieldUploadFile
                  callback={this.updateFile}
                  readOnly={this.state.paired}
                  title={"Additional Attachments"}
                  id={this.state.IncidentGUID}
                  formType={2}
                ></FormFieldUploadFile>
                {!this.props.capa && (
                  <FormFieldUploadFile
                    callback={this.updateFile}
                    readOnly={this.state.paired}
                    title={"Incident Closeout Picture"}
                    id={this.state.IncidentGUID}
                    formType={3}
                  ></FormFieldUploadFile>
                )}
                {this.state.ID && (
                  <Center>
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      style={{ fontSize: "30px", color: "green" }}
                    ></FontAwesomeIcon>
                    <br />
                    <b>Completed</b>
                    <br />
                    <i>
                      <LocalDate>{this.state.CompletedOn}</LocalDate>
                      <br />

                      {this.state.CompletedBy ? this.state.CompletedBy : ""}
                    </i>
                  </Center>
                )}
                {!this.state.paired && (
                  <>
                    <Hr />
                    <Row>
                      <Col>
                        <Center>
                          <SubmitButton
                            large={true}
                            text={
                              this.props.capa
                                ? "Submit Safety Alert"
                                : "Submit Manager Closeout"
                            }
                            icon={faCircleCheck}
                            submitting={this.state.submitting}
                          ></SubmitButton>
                        </Center>
                      </Col>
                    </Row>
                  </>
                )}
              </form>
            )}
            {this.state.loading && (
              <Center>
                <Loading abs={true} size="lg"></Loading>
              </Center>
            )}
          </Col>
        </Row>
      </FormContainer>
    );
  }
}
