import {
  faCheckCircle,
  faCircleCheck,
  faCircleExclamation,
  faInfoCircle,
  faPerson,
  faPersonFallingBurst,
  faShieldAlt,
  faSignIn,
  faSignOut,
  faSort,
  faTicket,
  faUser,
  faUserTie,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { toast } from "react-toastify";
import { Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { ChangeTypes, EmployeeType } from "../../../data/enums";
import {
  getBusinessUnit,
  getUserInfo,
  isBrowser,
  setBusinessUnit,
  setUserInfo,
} from "../../../services/auth";
import Center from "../../atoms/center";
import FloatingButton from "../../atoms/floatingButtons";
import FormContainer from "../../atoms/formContainer";
import FormDropdown from "../../atoms/formDropdown";
import FormField from "../../atoms/formField";
import Hr from "../../atoms/hr";
import LargeButton from "../../atoms/largeButton";
import SubmitButton from "../../atoms/submitButton";
import TabButton from "../../atoms/tabButton";
import IncidentReportHistory from "./incidentReportHistory";
import IncidentTypeForm from "./incidentType";
import queryString from "query-string";
import FadeContainer from "../../atoms/fadeContainer";
import PageMessage from "../../atoms/pageMessage";
import FormHeaderTitle from "../../atoms/formHeaderTitle";
import {
  CheckManagerStatus,
  GetHomeDropdowns,
} from "../../../services/incidentReport";
import styled from "styled-components";
import { Trans } from "react-i18next";
import FormFieldType from "../../atoms/formFieldType";
import UserCard from "../../molecules/userCard";

const Count = styled.div`
  background-color: #00b536;
  padding: 0px 10px;
  border-radius: 29px;
  position: absolute;
  right: -18px;
  top: -20px;
  color: white;
  border: 2px solid #d6fbe5;
  font-weight: bold;
  font-size: 20px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  @media screen and (max-width: 768px) {
    right: 0px;
    font-size: 15px;
    top: -14px;
  }
`;

const PhoneNav = styled.div`
  @media screen and (max-width: 768px) {
    position: fixed;
    bottom: 0;
    background-color: #e9e9e9;
    border-top: 2px solid #0a498e;
    left: 0;
    z-index: 9999999;
    width: 100%;
  }
`;
const NavCol = styled(Col)`
  @media screen and (max-width: 768px) {
    padding: 0px !important;
  }
`;

const HideXS = styled.span`
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const initialState = {
  ID: null,
  GUID: "",
  Name: "",
  Email: "",
  IncidentNumber: "",
  Status: "",
  EmployeeNumber: "",
  BusinessUnit: 0,
  EmployeeType: 0,
  ManagerEmail: "",
  businessUnits: [],
  accounts: [],
  tab: 1,
  submitting: false,
  isManager: false,
  IncidentDate: new Date(),
  showManager: false,
  managerOpenItems: null,
  eHSOpenItems: null,
  myOpenItems: null,
  isEHS: null,
  SSO: false,
  redirect: "",
  showNeedInfo: false,
};
export default class IncidentReportForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }
  componentDidMount() {
    if (isBrowser()) {
      this.setState({ GUID: crypto.randomUUID() });
    }
    this.populateUserInfo();

    this.checkManager();
    var query = queryString.parse(window.location.search);
    if (getUserInfo() === null) {
      if (query !== undefined && query["id"] !== undefined) {
        this.setState({
          redirect: "?redirect=" + query["id"],
          showNeedInfo: false,
        });
      }
    } else {
      if (query !== undefined && query["id"] !== undefined) {
        this.setState({
          tab: 5,
          GUID: query["id"],
          showNeedInfo:
            getBusinessUnit() === "" ||
            getBusinessUnit() === 0 ||
            getBusinessUnit() === "0",
          redirect: "?redirect=" + query["id"],
        });
      }
    }
  }

  checkManager = () => {
    var info = getUserInfo();
    if (info !== null) {
      CheckManagerStatus(getUserInfo().Email).then((result) => {
        if (result.err) {
          toast.error(result.err.message);
        } else {
          this.setState({
            showManager: result.IsManager,
            managerOpenItems: result.ManagerOpenItems,
            myOpenItems: result.MyOpenItems,
            eHSOpenItems: result.EHSOpenItems,
            isEHS: result.IsEHS,
          });
        }
      });
    }
  };

  signOut = () => {
    setUserInfo(null);
    this.setState({
      Name: "",
      Email: "",
      EmployeeNumber: "",
      EmployeeType: 0,
      ManagerEmail: "",
      UserLocation: "",
      Department: "",
      BusinessUnit: 0,
      tab: 1,
      showManager: false,
      managerOpenItems: null,
      eHSOpenItems: null,
      myOpenItems: null,
      isEHS: false,
      SSO: false,
    });
    toast.success("You have been logged out successfully");
  };

  populateUserInfo = () => {
    GetHomeDropdowns().then((res) => {
      this.setState({
        businessUnits: res.BusinessUnits,
        accounts: res.OneSourceAccounts,
      });
    });

    var savedUser = getUserInfo();
    if (savedUser !== "" && savedUser !== null) {
      this.setState(savedUser);
    }
  };
  clear = () => {
    var showManager = this.state.showManager;
    var managerOpenItems = this.state.managerOpenItems;
    var isEHS = this.state.isEHS;
    var myOpenItems = this.state.myOpenItems;
    var eHSOpenItems = this.state.eHSOpenItems;
    this.setState(
      {
        ...initialState,
        showManager: showManager,
        managerOpenItems: managerOpenItems,
        isEHS: isEHS,
        eHSOpenItems: eHSOpenItems,
        myOpenItems: myOpenItems,
      },
      () => {
        if (isBrowser()) {
          this.setState({ tab: 2, GUID: crypto.randomUUID() });
          this.populateUserInfo();
          window.scrollTo(0, 0);
        }
      }
    );
    if (
      typeof window !== "undefined" &&
      document?.getElementsByTagName("form").length > 0
    ) {
      document?.getElementsByTagName("form")[0].removeAttribute("class");
    }
  };

  updateTab = (key) => {
    if (key === 2) {
      this.clear();
    } else {
      this.checkManager();
      this.setState({ tab: key });
    }
    if (isBrowser()) {
      window.scrollTo(0, 0);
    }
  };

  handleUserInput = (event, key = ChangeTypes.NONE) => {
    const name = event.target.name;
    const value = event.target.value;
    switch (key) {
      case ChangeTypes.UPPER:
        this.setState({ [name]: value.toUpperCase().trim() });
        break;
      case ChangeTypes.NONE:
        this.setState({ [name]: value.trim() });
        break;
      case ChangeTypes.CHECK:
        this.setState({ [name]: event.target.checked });
        break;
      case ChangeTypes.NOTES:
      default:
        this.setState({ [name]: value });
        break;
    }
  };
  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ submitting: true }, () => {
      this.validateForm();
    });
  };

  validateForm = () => {
    let isValid = true;

    if (this.state.EmployeeType === 0) {
      toast.error("Employee Type is required.");
      isValid = false;
    }

    if (this.state.BusinessUnit === 0) {
      toast.error("Business Unit is required.");
      isValid = false;
    }

    if (isValid) {
      var sso = getUserInfo() !== null ? getUserInfo().SSO : false;
      var user = {
        EmployeeType: this.state.EmployeeType,
        Name: this.state.Name,
        Email: this.state.Email,
        EmployeeNumber: this.state.EmployeeNumber,
        ManagerEmail: this.state.ManagerEmail,
        BusinessUnit: this.state.BusinessUnit,
        SSO: sso,
        Department: this.state.Department,
        UserLocation: this.state.UserLocation,
      };
      setUserInfo(user);
      setBusinessUnit(this.state.BusinessUnit);
      this.checkManager();
      toast.success("User Information has been Updated");
      this.submitForm();
      this.setState({ showNeedInfo: false });
    } else {
      this.setState({ submitting: false });
    }
  };

  submitForm() {
    this.setState({ submitting: false });
  }

  serialNumberSearch = (search, manager, number) => {
    this.setState(
      {
        GUID: search,
        IncidentNumber: number,
        isManager: false,
      },
      () => {
        this.setState({ tab: 5 });
      }
    );
  };
  serialNumberSearchManager = (search, manager, number) => {
    this.setState({ tab: 2 }, () => {
      this.setState(
        {
          tab: 5,
          GUID: search,
          IncidentNumber: number,
          isManager: true,
        },
        () => {}
      );
    });
  };

  render() {
    return (
      <>
        <Modal size="lg" isOpen={this.state.showNeedInfo} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>
            <FontAwesomeIcon icon={faPerson}></FontAwesomeIcon>
            &nbsp;Additional Information Needed
          </ModalHeader>
          <ModalBody>
            <>
              {getBusinessUnit() === 0 && (
                <PageMessage style={{ color: "#a40000" }}>
                  <FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon>
                  &nbsp; Please fill out Business Unit to view and submit
                  tickets
                </PageMessage>
              )}
              <FormDropdown
                value={this.state.BusinessUnit}
                onChange={this.handleUserInput}
                name="BusinessUnit"
                required={true}
                options={this.state.businessUnits}
                title={<Trans>Business Unit</Trans>}
                dropdownContentType="Business Unit"
              ></FormDropdown>
              
              <Hr />
              <Center>
                <Row>
                  <Col>
                    <LargeButton
                      large={true}
                      icon={faCircleCheck}
                      onClick={this.handleSubmit}
                    >
                      <FontAwesomeIcon icon={faCheckCircle}></FontAwesomeIcon>
                      &nbsp;
                      <Trans>Update Information</Trans>
                    </LargeButton>
                  </Col>
                </Row>
              </Center>
            </>
          </ModalBody>
        </Modal>
        <PhoneNav>
          <Row>
            <NavCol>
              <TabButton
                lg={true}
                onClick={() => this.updateTab(1)}
                isActive={this.state.tab === 1}
                icon={faUser}
              >
                <Trans>
                  <HideXS>Personal</HideXS> Info
                </Trans>
              </TabButton>
            </NavCol>
            <NavCol>
              <TabButton
                onClick={() => this.updateTab(2)}
                lg={true}
                disabled={getUserInfo() === null || getBusinessUnit() === 0}
                isActive={this.state.tab === 2}
                icon={faPersonFallingBurst}
              >
                <Trans>New Ticket</Trans>
              </TabButton>
            </NavCol>

            <NavCol>
              <TabButton
                onClick={() => this.updateTab(3)}
                lg={true}
                disabled={getUserInfo() === null || getBusinessUnit() === 0}
                isActive={this.state.tab === 3}
                icon={faTicket}
              >
                <Trans>My Tickets</Trans>
                {this.state.myOpenItems !== null && (
                  <Count>{this.state.myOpenItems}x</Count>
                )}
              </TabButton>
            </NavCol>
            {this.state.showManager && (
              <NavCol>
                <TabButton
                  onClick={() => this.updateTab(4)}
                  lg={true}
                  disabled={getUserInfo() === null || getBusinessUnit() === 0}
                  isActive={this.state.tab === 4}
                  icon={faUserTie}
                >
                  <Trans>
                    Manager <HideXS>Closeout</HideXS>
                  </Trans>
                  {this.state.managerOpenItems !== null && (
                    <Count>{this.state.managerOpenItems}x</Count>
                  )}
                </TabButton>
              </NavCol>
            )}
            {this.state.isEHS && (
              <NavCol>
                <TabButton
                  onClick={() => this.updateTab(6)}
                  lg={true}
                  disabled={getUserInfo() === null || getBusinessUnit() === 0}
                  isActive={this.state.tab === 6}
                  icon={faShieldAlt}
                  style={{
                    backgroundColor: "gold",
                    color: "#474747",
                    fontWeight: "bold",
                  }}
                >
                  <Trans>
                    {" "}
                    <HideXS>EHS&nbsp;</HideXS>Admin
                  </Trans>
                  {this.state.eHSOpenItems !== null && (
                    <Count>{this.state.eHSOpenItems}x</Count>
                  )}
                </TabButton>
              </NavCol>
            )}
          </Row>
        </PhoneNav>
        {this.state.redirect && getUserInfo() === null && (
          <PageMessage style={{ color: "#a40000" }}>
            <FontAwesomeIcon icon={faCircleExclamation}></FontAwesomeIcon>
            &nbsp;You must be logged in to view tickets.
          </PageMessage>
        )}

        {this.state.tab === 1 && (
          <FadeContainer>
            <form id="form" onSubmit={this.handleSubmit}>
              <FormContainer style={{ minHeight: "auto" }}>
                <FormHeaderTitle>
                  <FontAwesomeIcon icon={faUser}></FontAwesomeIcon>
                  &nbsp;PerkinElmer Employee
                  {this.state.showManager && !this.state.isEHS && (
                    <FormFieldType lg={true}>Manager</FormFieldType>
                  )}
                  {this.state.isEHS && (
                    <FormFieldType lg={true}>EHS Rep</FormFieldType>
                  )}
                </FormHeaderTitle>
                <Center>
                  {this.state.SSO && (
                    <>
                      <UserCard user={this.state.Email}></UserCard>
                      <PageMessage>Verified PerkinElmer Employee</PageMessage>
                      <FontAwesomeIcon
                        icon={faCircleCheck}
                        style={{ color: "green", fontSize: "50px" }}
                      ></FontAwesomeIcon>
                      <Hr />
                      {getBusinessUnit() === 0 && (
                        <PageMessage style={{ color: "#a40000" }}>
                          <FontAwesomeIcon
                            icon={faInfoCircle}
                          ></FontAwesomeIcon>
                          &nbsp; Please fill out Business Unit to view and
                          submit tickets
                        </PageMessage>
                      )}
                      <FormDropdown
                        value={this.state.BusinessUnit}
                        onChange={this.handleUserInput}
                        name="BusinessUnit"
                        required={true}
                        options={this.state.businessUnits}
                        title={<Trans>Business Unit</Trans>}
                        dropdownContentType="Business Unit"
                      ></FormDropdown>

                      <Hr />
                      <Center>
                        <Row>
                          <Col>
                            <SubmitButton
                              large={true}
                              text={<Trans>Update Information</Trans>}
                              icon={faCircleCheck}
                              submitting={this.state.submitting}
                            ></SubmitButton>
                          </Col>
                        </Row>
                      </Center>
                    </>
                  )}
                  {!this.state.SSO && (
                    <PageMessage>
                      <FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon>
                      &nbsp; Login with your PerkinElmer Microsoft Account
                    </PageMessage>
                  )}

                  {!this.state.SSO && (
                    <LargeButton
                      href={`${process.env.GATSBY_API_URL}/SSO/SignInEHS${this.state.redirect}`}
                    >
                      <FontAwesomeIcon icon={faSignIn}></FontAwesomeIcon>&nbsp;
                      Login with SSO
                    </LargeButton>
                  )}
                </Center>
              </FormContainer>
              {this.state.SSO && (
                <>
                  <Hr />
                  <FormContainer style={{ minHeight: "auto" }}>
                    <FormHeaderTitle>
                      <FontAwesomeIcon icon={faUser}></FontAwesomeIcon>&nbsp;
                      Logout of PerkinElmer Account
                    </FormHeaderTitle>
                    <Center>
                      <LargeButton onClick={this.signOut}>
                        <FontAwesomeIcon icon={faSignOut}></FontAwesomeIcon>
                        &nbsp; Logout
                      </LargeButton>
                    </Center>
                  </FormContainer>
                </>
              )}
              {!this.state.SSO && (
                <>
                  <Center>
                    <FontAwesomeIcon
                      style={{
                        fontSize: "50px",
                        color: "#9e9e9e",
                        marginBottom: "12px",
                      }}
                      icon={faSort}
                    ></FontAwesomeIcon>
                  </Center>
                  <FormContainer>
                    <FormHeaderTitle>
                      <FontAwesomeIcon icon={faPerson}></FontAwesomeIcon>
                      &nbsp;
                      <Trans>Guest Information</Trans>
                      {this.state.showManager && (
                        <FormFieldType lg={true}>Manager</FormFieldType>
                      )}
                      {this.state.isEHS && (
                        <FormFieldType lg={true}>EHS Rep</FormFieldType>
                      )}
                    </FormHeaderTitle>
                    {(getUserInfo() === null || getBusinessUnit() === 0) && (
                      <PageMessage>
                        <FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon>
                        &nbsp; Please fill out your personal information to view
                        and submit tickets.
                      </PageMessage>
                    )}

                    <FormDropdown
                      value={this.state.EmployeeType}
                      onChange={this.handleUserInput}
                      name="EmployeeType"
                      title={<Trans>Employee Type</Trans>}
                      required={true}
                      options={EmployeeType}
                      readOnly={this.state.SSO}
                      dropdownContentType="Type of Employee"
                    ></FormDropdown>
                    <FormField
                      title="Name"
                      value={this.state.Name}
                      disabled={this.state.SSO}
                      onChange={(event) =>
                        this.handleUserInput(event, ChangeTypes.NOTES)
                      }
                      name="Name"
                      required={true}
                    ></FormField>
                    <FormField
                      title={<Trans>Email</Trans>}
                      value={this.state.Email}
                      onChange={this.handleUserInput}
                      name="Email"
                      required={true}
                      disabled={this.state.SSO}
                    ></FormField>
                    {(this.state.EmployeeType === "Employee" ||
                      this.state.EmployeeType === "Contractor") && (
                      <FormField
                        title={<Trans>Employee Number</Trans>}
                        value={this.state.EmployeeNumber}
                        disabled={this.state.SSO}
                        onChange={this.handleUserInput}
                        maxLength="20"
                        name="EmployeeNumber"
                      ></FormField>
                    )}
                    <FormField
                      title={<Trans>Manager Email</Trans>}
                      value={this.state.ManagerEmail}
                      disabled={this.state.SSO}
                      onChange={this.handleUserInput}
                      name="ManagerEmail"
                      required={true}
                    ></FormField>
                    <FormDropdown
                      value={this.state.BusinessUnit}
                      onChange={this.handleUserInput}
                      name="BusinessUnit"
                      required={true}
                      options={this.state.businessUnits}
                      title={<Trans>Business Unit</Trans>}
                      dropdownContentType="Business Unit"
                    ></FormDropdown>
                    <Hr />
                    <Center>
                      <Row>
                        <Col>
                          <SubmitButton
                            large={true}
                            text={<Trans>Update Information</Trans>}
                            icon={faCircleCheck}
                            submitting={this.state.submitting}
                          ></SubmitButton>
                        </Col>
                      </Row>
                    </Center>
                    <Hr />
                  </FormContainer>
                </>
              )}
            </form>
          </FadeContainer>
        )}
        {this.state.tab === 2 && (
          <FadeContainer>
            <IncidentTypeForm
              guid={this.state.GUID}
              isNew={true}
              updateTab={this.updateTab}
              number={this.state.IncidentNumber}
            ></IncidentTypeForm>
          </FadeContainer>
        )}

        {this.state.tab === 3 && (
          <FadeContainer>
            <IncidentReportHistory
              search={this.serialNumberSearch}
              pageTitle="My EHS Incident Tickets"
              ref={this.child}
            ></IncidentReportHistory>
          </FadeContainer>
        )}
        {this.state.tab === 4 && (
          <FadeContainer>
            <IncidentReportHistory
              search={this.serialNumberSearchManager}
              pageTitle="My Manager Closeout Tickets"
              ref={this.child}
              isManager={true}
            ></IncidentReportHistory>
          </FadeContainer>
        )}

        {this.state.tab === 5 && (
          <FadeContainer>
            {this.state.GUID && (
              <>
                <IncidentTypeForm
                  guid={this.state.GUID}
                  updateTab={this.updateTab}
                  isManager={this.state.isManager}
                  isEHS={this.state.isEHS}
                ></IncidentTypeForm>
              </>
            )}
          </FadeContainer>
        )}

        {this.state.tab === 6 && (
          <FadeContainer>
            {this.state.isEHS && (
              <>
                <IncidentReportHistory
                  search={this.serialNumberSearch}
                  isEHS={true}
                  pageTitle="All Tickets"
                  ref={this.child}
                ></IncidentReportHistory>
              </>
            )}
          </FadeContainer>
        )}

        {getUserInfo() !== null &&
          getBusinessUnit() != null &&
          getBusinessUnit() != 0 && (
            <FloatingButton>
              <LargeButton Draft={true} onClick={this.clear}>
                <FontAwesomeIcon icon={faPersonFallingBurst}></FontAwesomeIcon>
                <span className="showMe">
                  &nbsp;<Trans>New Ticket</Trans>
                </span>
              </LargeButton>
            </FloatingButton>
          )}
      </>
    );
  }
}
