import React from "react";
import { toast } from "react-toastify";
import { Col, Row } from "reactstrap";
import { getUserInfo } from "../../../services/auth";
import SubmitButton from "../../atoms/submitButton";
import FormContainer from "../../atoms/formContainer";
import Hr from "../../atoms/hr";
import Label from "../../atoms/label";
import LocalDate from "../../atoms/localDate";
import FormFieldQuestion from "../../atoms/formFieldQuestion";
import FormFieldRadioQuestion from "../../atoms/formFieldRadioQuestion";
import {
  GetInvestigationReportForm,
  SubmitInvestigationReportForm,
} from "../../../services/incidentReport";
import FormFieldUploadFile from "../../molecules/uploadFile";
import { ChangeTypes } from "../../../data/enums";
import {
  faCheckCircle,
  faCircleCheck,
  faClipboardCheck,
  faImage,
  faListCheck,
  faTriangleExclamation,
  faUserSecret,
} from "@fortawesome/free-solid-svg-icons";
import FormHeader from "../../atoms/formHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Center from "../../atoms/center";
import Input from "../../atoms/input";
import AdjustButton from "../../atoms/adjustButton";
import Required from "../../atoms/required";
import FormFieldType from "../../atoms/formFieldType";
import FormDropdown from "../../atoms/formDropdown";
import FormHeaderTitle from "../../atoms/formHeaderTitle";
import IRCorrectiveActions from "./correctiveActions";
import PageMessage from "../../atoms/pageMessage";
import IRRootCauses from "./rootCauses";

/* eslint-disable */
const initialState = {
  ID: null,
  IncidentGUID: "",
  IncidentForm: null,
  CompletedBy: "",
  CAPANeeded: false,
  CompletedOn: "",
  CompletedByName: "",
  submitting: false,
  formValid: true,
  searchError: false,
  paired: false,
  ActualLostDays: null,
  ActualRestrictedDays: null,
  ActualTransferDays: null,
  Severity: 0,
  RootCauseType: 0,
  RootCause: "",
  QEmployeeWearPPE: "",
  QEmployeeWearRightPPE: "",
  QToolsInspected: "",
  QEmployeeTrained: null,
  QStillAnIssue: "",
  QTaskDoneSame: "",
  QOutsideJobDuties: "",
  QOutsideWorkHours: "",
  QToolsSpecificTask: "",
  ModifiedBy: "",
  correctiveActions: [],
  canSubmit: false,
  canSubmitRC: true,
  isInjury: false,
};

export default class InvestigationReportCAPA extends React.Component {
  constructor(props) {
    super(props);

    this.child = React.createRef();
    this.state = initialState;

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleUserInput = this.handleUserInput.bind(this);
    this.validateForm = this.validateForm.bind(this);
  }

  componentDidMount = () => {
    this.setState(
      {
        IncidentGUID: this.props.guid,
        CompletedBy: getUserInfo().Email,
        paired: this.props.disable && !this.props.isEHS,
        isInjury: this.props.typeName[0].key === "Injury/Illness",
      },
      () => {
        if (this.state.IncidentGUID !== "") {
          this.serialNumberSearch();
        }
      }
    );
  };

  clear = () => {
    this.setState(initialState);
    if (
      typeof window !== "undefined" &&
      document?.getElementsByTagName("form")[0]
    ) {
      document?.getElementsByTagName("form")[0].removeAttribute("class");
    }
  };
  handleTimeInput = (date) => {
    if (date) {
      this.setState({ ClassificationDate: new Date(date) });
    } else {
      this.setState({ ClassificationDate: new Date() });
    }
  };
  handleTimeInputReview = (date) => {
    if (date) {
      this.setState({ ReviewDate: new Date(date) });
    } else {
      this.setState({ ReviewDate: new Date() });
    }
  };

  submitForm(draft = true) {
    let newState = JSON.parse(JSON.stringify(this.state));
    SubmitInvestigationReportForm({ formData: newState })
      .then((res) => {
        if (res.data.HasErrors) {
          res.data.ErrorList.forEach((item) => {
            toast.error(item);
          });
          this.setState({
            submitting: false,
          });
        } else {
          toast.success("CAPA Investigation has been closed");
          this.props.updateTab(4);
        }
      })
      .catch((error) => {
        toast.error("There was an error submitting the form.");
        this.setState({ submitting: false });
      });
  }

  serialNumberSearch = (search = "", showMessage = true) => {
    GetInvestigationReportForm(search !== "" ? search : this.state.IncidentGUID)
      .then((res) => {
        if (typeof res === "string") {
          return;
        }
        if (res.HasErrors) {
          res.ErrorList.forEach((item) => {
            toast.error(item);
          });
        } else {
          if (res) {
            this.setState(
              {
                ...res,
                paired: this.state.disable,
              },
              () => {
                if (showMessage) {
                }
                if (typeof window !== "undefined") {
                  window.scrollTo(0, 0);
                }
              }
            );
          } else {
            this.setState({
              IncidentGUID: res.IncidentForm.GUID,
              IncidentForm: res.IncidentForm,
              paired: this.state.disable,
            });
          }
        }
      })
      .catch((err) => {
        toast.error("Error");
      });
  };

  validateForm = () => {
    let isValid = true;
    if (this.state.Severity === 0 && !this.state.isInjury) {
      toast.error("Severity is required.");
      isValid = false;
    }

    if (isValid) {
      this.submitForm(false);
    } else {
      this.setState({ submitting: false });
    }
  };

  updateCorrectiveActions = (actions) => {
    var items = actions.filter((x) => x.FinalCompletionDate === null);
    this.setState({
      correctiveActions: actions,
      canSubmit: actions.length > 0 && items.length === 0,
    });
  };

  updateRootCauses = (actions) => {
    this.setState({
      canSubmitRC: actions.length > 0,
    });
  };
  handleSubmit = (event) => {
    event.preventDefault();
    this.setState(
      {
        submitting: true,
        ModifiedBy: getUserInfo().Email,
      },
      () => {
        this.validateForm();
      }
    );
  };

  handleUserInputArea = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({ [name]: value });
  };

  buttonAdjust = (value, field, isIncrease) => {
    if (isNaN(parseInt(value))) {
      value = 0;
    } else {
      value = parseInt(value);
    }
    let newVal = isIncrease ? value + 1 : value - 1;
    if (newVal >= 0 && newVal <= 1000) {
      this.setState({ [field]: newVal });
    }
  };

  handleUserInput = (event, key = ChangeTypes.NONE) => {
    const name = event.target.name;
    const value = event.target.value;

    if (name === "Type") {
      var type = this.state.allBaseTypes?.filter(
        (x) => x.value.toString() === value
      );
      if (type.length > 0) {
        this.loadTypeDef(type[0].label);
      }
    }
    if (name === "Severity" && value === "Low") {
      this.setState({ canSubmitRC: true });
    }
    switch (key) {
      case ChangeTypes.UPPER:
        this.setState({ [name]: value.toUpperCase().trim() });
        break;
      case ChangeTypes.NONE:
        this.setState({ [name]: value.trim() });
        break;
      case ChangeTypes.CHECK:
        this.setState({ [name]: value === "na" ? null : value === "yes" });
        break;
      case ChangeTypes.NOTES:
      default:
        this.setState({ [name]: value });
        break;
    }
  };
  updateState = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  handleCheckChange = (event) => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  render() {
    return (
      <>
        <Row>
          <Col>
            <FormContainer>
              <FormHeaderTitle>
                <FontAwesomeIcon icon={faUserSecret}></FontAwesomeIcon>
                &nbsp;Investigation Report CAPA&nbsp;
                {this.props.typeName?.length > 0 && (
                  <FormFieldType lg={true}>
                    {this.props.typeName[0].key}
                  </FormFieldType>
                )}
              </FormHeaderTitle>

              {!this.state.loading && (
                <form id="form" onSubmit={this.handleSubmit}>
                  {this.state.isInjury && (
                    <>
                      <Row style={{ marginBottom: "5px" }}>
                        <Col xs="12">
                          <Label>
                            Actual Lost Days{" "}
                            <i style={{ fontWeight: "normal" }}>
                              - The number of days an employee is absent because
                              of a work-related illness or injury
                            </i>
                            <Required></Required>
                            <FormFieldType>Injury/Illness</FormFieldType>
                          </Label>
                        </Col>
                        <Col xs="12" sm="2" style={{ position: "relative" }}>
                          <Input
                            disabled={this.state.paired}
                            type="number"
                            value={this.state.ActualLostDays}
                            onChange={this.handleUserInput}
                            name="ActualLostDays"
                            required={true}
                          ></Input>
                          <AdjustButton
                            disabled={this.state.paired}
                            onUpClick={() =>
                              this.buttonAdjust(
                                this.state.ActualLostDays,
                                "ActualLostDays",
                                true
                              )
                            }
                            onDownClick={() =>
                              this.buttonAdjust(
                                this.state.ActualLostDays,
                                "ActualLostDays",
                                false
                              )
                            }
                          ></AdjustButton>
                        </Col>
                      </Row>
                      <Row style={{ marginBottom: "5px" }}>
                        <Col xs="12">
                          <Label>
                            Actual restricted duty days (calendar)
                            <i style={{ fontWeight: "normal" }}>
                              - The number of days an employee is placed under
                              work restrictions (e.g. no heavy lifting, must sit
                              while working, etc.)
                            </i>
                            <Required></Required>
                            <FormFieldType>Injury/Illness</FormFieldType>
                          </Label>
                        </Col>
                        <Col xs="12" sm="2" style={{ position: "relative" }}>
                          <Input
                            disabled={this.state.paired}
                            type="number"
                            value={this.state.ActualRestrictedDays}
                            onChange={this.handleUserInput}
                            name="ActualRestrictedDays"
                            required={true}
                          ></Input>
                          <AdjustButton
                            disabled={this.state.paired}
                            onUpClick={() =>
                              this.buttonAdjust(
                                this.state.ActualRestrictedDays,
                                "ActualRestrictedDays",
                                true
                              )
                            }
                            onDownClick={() =>
                              this.buttonAdjust(
                                this.state.ActualRestrictedDays,
                                "ActualRestrictedDays",
                                false
                              )
                            }
                          ></AdjustButton>
                        </Col>
                      </Row>
                      <Row style={{ marginBottom: "5px" }}>
                        <Col xs="12">
                          <Label>
                            Actual job transfer days (calendar)
                            <i style={{ fontWeight: "normal" }}>
                              - The number of days an employee is transferred to
                              another job because they could not fulfill their
                              normal duties
                            </i>
                            <Required></Required>
                            <FormFieldType>Injury/Illness</FormFieldType>
                          </Label>
                        </Col>
                        <Col xs="12" sm="2" style={{ position: "relative" }}>
                          <Input
                            disabled={this.state.paired}
                            type="number"
                            value={this.state.ActualTransferDays}
                            onChange={this.handleUserInput}
                            name="ActualTransferDays"
                            required={true}
                          ></Input>
                          <AdjustButton
                            disabled={this.state.paired}
                            onUpClick={() =>
                              this.buttonAdjust(
                                this.state.ActualTransferDays,
                                "ActualTransferDays",
                                true
                              )
                            }
                            onDownClick={() =>
                              this.buttonAdjust(
                                this.state.ActualTransferDays,
                                "ActualTransferDays",
                                false
                              )
                            }
                          ></AdjustButton>
                        </Col>
                      </Row>
                    </>
                  )}

                  {this.state.isInjury && (
                    <>
                      <FormHeader>
                        <FontAwesomeIcon
                          icon={faClipboardCheck}
                        ></FontAwesomeIcon>
                        &nbsp;Systematic Issue Screening
                      </FormHeader>

                      <FormFieldRadioQuestion
                        title="Did the employee wear the PPE?"
                        filter={"Injury/Illness"}
                        readOnly={this.state.paired}
                        na={true}
                        name="QEmployeeWearPPE"
                        required={true}
                        value={this.state.QEmployeeWearPPE}
                        onChange={(event) =>
                          this.handleUserInput(event, ChangeTypes.CHECK)
                        }
                      />
                      <FormFieldRadioQuestion
                        title="Did the employee wear the right PPE?"
                        filter={"Injury/Illness"}
                        readOnly={this.state.paired}
                        na={true}
                        name="QEmployeeWearRightPPE"
                        required={true}
                        value={this.state.QEmployeeWearRightPPE}
                        onChange={(event) =>
                          this.handleUserInput(event, ChangeTypes.CHECK)
                        }
                      />
                      <FormFieldRadioQuestion
                        title="Were the equipment/tools used inspected and in good condition?"
                        filter={"Injury/Illness"}
                        readOnly={this.state.paired}
                        na={true}
                        name="QToolsInspected"
                        required={true}
                        value={this.state.QToolsInspected}
                        onChange={(event) =>
                          this.handleUserInput(event, ChangeTypes.CHECK)
                        }
                      />
                      <FormFieldRadioQuestion
                        title="Was the employee trained/qualified to do the task?"
                        filter={"Injury/Illness"}
                        readOnly={this.state.paired}
                        name="QEmployeeTrained"
                        required={true}
                        value={this.state.QEmployeeTrained}
                        onChange={(event) =>
                          this.handleUserInput(event, ChangeTypes.CHECK)
                        }
                      />
                      <FormFieldRadioQuestion
                        title="Is the task done in the same manner by all employees?"
                        filter={"Injury/Illness"}
                        readOnly={this.state.paired}
                        na={true}
                        name="QTaskDoneSame"
                        required={true}
                        value={this.state.QTaskDoneSame}
                        onChange={(event) =>
                          this.handleUserInput(event, ChangeTypes.CHECK)
                        }
                      />
                      <FormFieldRadioQuestion
                        title="Did employee do the job outside their job duties? "
                        filter={"Injury/Illness"}
                        readOnly={this.state.paired}
                        name="QOutsideJobDuties"
                        na={true}
                        required={true}
                        value={this.state.QOutsideJobDuties}
                        onChange={(event) =>
                          this.handleUserInput(event, ChangeTypes.CHECK)
                        }
                      />
                      <FormFieldRadioQuestion
                        title="Did the incident happened outside normal work hours?"
                        filter={"Injury/Illness"}
                        readOnly={this.state.paired}
                        name="QOutsideWorkHours"
                        na={true}
                        required={true}
                        value={this.state.QOutsideWorkHours}
                        onChange={(event) =>
                          this.handleUserInput(event, ChangeTypes.CHECK)
                        }
                      />
                      <FormFieldRadioQuestion
                        title="Did the employee use the tools designed for the specific task?"
                        filter={"Injury/Illness"}
                        readOnly={this.state.paired}
                        name="QToolsSpecificTask"
                        na={true}
                        required={true}
                        value={this.state.QToolsSpecificTask}
                        onChange={(event) =>
                          this.handleUserInput(event, ChangeTypes.CHECK)
                        }
                      />
                    </>
                  )}
                  <FormHeader>
                    <FontAwesomeIcon icon={faListCheck}></FontAwesomeIcon>
                    &nbsp;Corrective / Preventive Actions
                  </FormHeader>
                  {this.state.IncidentGUID && (
                    <IRCorrectiveActions
                      updateCA={this.updateCorrectiveActions}
                      disable={this.state.paired}
                      guid={this.state.IncidentGUID}
                    ></IRCorrectiveActions>
                  )}
                  {!this.state.isInjury && (
                    <FormDropdown
                      value={this.state.Severity}
                      onChange={this.handleUserInput}
                      stackLabel={true}
                      name="Severity"
                      title="Severity?"
                      filter="Near-Miss"
                      required={true}
                      readOnly={this.state.paired}
                      options={[
                        { key: "High", value: "High", label: "High" },
                        { key: "Low", value: "Low", label: "Low" },
                      ]}
                      dropdownContentType="Severity"
                    ></FormDropdown>
                  )}
                  {(this.state.Severity === "High" || this.state.isInjury) && (
                    <>
                      <FormHeader>
                        <FontAwesomeIcon icon={faListCheck}></FontAwesomeIcon>
                        &nbsp;Root Causes
                      </FormHeader>
                      <IRRootCauses
                        disable={this.state.paired}
                        updateRC={this.updateRootCauses}
                        guid={this.state.IncidentGUID}
                      ></IRRootCauses>
                    </>
                  )}
                  <FormFieldRadioQuestion
                    title="Is the issue still prevalent and a risk to safety?"
                    filter={"Manager"}
                    readOnly={this.state.paired}
                    name="QStillAnIssue"
                    value={this.state.QStillAnIssue}
                    required={true}
                    onChange={(event) =>
                      this.handleUserInput(event, ChangeTypes.CHECK)
                    }
                  />
                  <FormFieldQuestion
                    readOnly={this.state.paired}
                    question="Any Additional Notes?"
                    value={this.state.Notes}
                    filter={"Manager"}
                    onChange={this.handleUserInputArea}
                    name="Notes"
                    maxLength="500"
                  ></FormFieldQuestion>
                  <FormHeader>
                    <FontAwesomeIcon icon={faImage}></FontAwesomeIcon>
                    &nbsp;Attachments{" - "}
                    <span style={{ color: "#ba0000" }}>
                      (DO NOT UPLOAD MEDICAL FORMS)
                    </span>
                  </FormHeader>
                  <FormFieldUploadFile
                    callback={this.updateFile}
                    readOnly={this.state.paired}
                    title={"Additional Attachments"}
                    id={this.state.IncidentGUID}
                    formType={5}
                  ></FormFieldUploadFile>
                  {this.state.ID && (
                    <Center>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        style={{ fontSize: "30px", color: "green" }}
                      ></FontAwesomeIcon>
                      <br />
                      <b>Completed</b>
                      <br />
                      <i>
                        <LocalDate>{this.state.CompletedOn}</LocalDate>
                        <br />

                        {this.state.CompletedBy ? this.state.CompletedBy : ""}
                      </i>
                    </Center>
                  )}
                  {!this.state.paired && (
                    <>
                      <Hr />
                      {!this.state.canSubmit && (
                        <PageMessage style={{ color: "#cd0000" }}>
                          <FontAwesomeIcon
                            icon={faTriangleExclamation}
                          ></FontAwesomeIcon>
                          &nbsp;You must have at least one Corrective/Preventive
                          Action and all Corrective/Preventive Action(s) must
                          have a Final Completion End to submit and close out
                          this incident.
                        </PageMessage>
                      )}
                      {!this.state.canSubmitRC && (
                        <PageMessage style={{ color: "#cd0000" }}>
                          <FontAwesomeIcon
                            icon={faTriangleExclamation}
                          ></FontAwesomeIcon>
                          &nbsp;You must have at least one Root Cause to submit
                          and close out this incident.
                        </PageMessage>
                      )}
                      <Row>
                        <Col>
                          <Center>
                            <SubmitButton
                              large={true}
                              disabled={
                                !this.state.canSubmit || !this.state.canSubmitRC
                              }
                              text={"Submit CAPA Investigation Report"}
                              icon={faCircleCheck}
                              submitting={this.state.submitting}
                            ></SubmitButton>
                          </Center>
                        </Col>
                      </Row>
                    </>
                  )}
                </form>
              )}
              {this.state.loading && (
                <Center>
                  <Loading abs={true} size="lg"></Loading>
                </Center>
              )}
            </FormContainer>
          </Col>
        </Row>
      </>
    );
  }
}
