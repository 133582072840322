import React from "react";
import { ChangeTypes } from "../../../data/enums";
import FormDropdown from "../../atoms/formDropdown";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import PageMessage from "../../atoms/pageMessage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormFieldRadioQuestion from "../../atoms/formFieldRadioQuestion";

const initialState = {
  ID: null,
  NearMissType: 0,
  QIsSevere: null,
};

export default class TypeNearMiss extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }
  componentDidMount() {
    this.setState(this.props.state, () => {
      this.setState(
        {
          IncidentGUID: this.props.guid,
          IncidentNumber: this.props.number,
        },
        () => {
          this.props.update(this.state);
          this.props.updateNearMissType(this.state.QIsSevere);
        }
      );
    });
  }

  handleUserInput = (event, key = ChangeTypes.NONE) => {
    const name = event.target.name;
    const value = event.target.value;

    if (name === "QIsSevere") {
      this.props.updateNearMissType(value === "yes");
    }
    switch (key) {
      case ChangeTypes.UPPER:
        this.setState({ [name]: value.toUpperCase().trim() }, () => {
          this.props.update(this.state);
        });
        break;
      case ChangeTypes.NONE:
        this.setState({ [name]: value.trim() }, () => {
          this.props.update(this.state);
        });
        break;
      case ChangeTypes.CHECK:
        this.setState({ [name]: value === "yes" }, () => {
          this.props.update(this.state);
        });
        break;
      case ChangeTypes.NOTES:
      default:
        this.setState({ [name]: value }, () => {
          this.props.update(this.state);
        });
        break;
    }
  };

  render() {
    return (
      <>
        <FormDropdown
          value={this.state.NearMissType}
          onChange={this.handleUserInput}
          name="NearMissType"
          title="Near-Miss Type"
          stackLabel={true}
          filter="Near-Miss"
          readOnly={this.props.readOnly}
          required={true}
          options={this.props.dropdowns.filter((x) => x.typeID === 2)}
          dropdownContentType="Type of Near-Miss"
        ></FormDropdown>
        <FormFieldRadioQuestion
          title="Could this Incident have caused severe injuries, such as death, hospitalization, or broken bones, or resulted in a toxic or hazardous spill to the environment?"
          filter={"Near-Miss"}
          readOnly={this.props.readOnly}
          name="QIsSevere"
          value={this.state.QIsSevere}
          required={true}
          onChange={(event) => this.handleUserInput(event, ChangeTypes.CHECK)}
        />
        {this.state.QIsSevere && (
          <PageMessage style={{ color: "#cd0000" }}>
            <FontAwesomeIcon icon={faTriangleExclamation}></FontAwesomeIcon>
            &nbsp;CAPA Investigation is required for the this ticket.
          </PageMessage>
        )}
      </>
    );
  }
}
