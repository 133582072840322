import React from "react";
import { ChangeTypes } from "../../../data/enums";
import FormDropdown from "../../atoms/formDropdown";
import Hr from "../../atoms/hr";
import Label from "../../atoms/label";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faInfoCircle,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";

const initialState = {
  ID: null,
  HazardIDType: 0,
};

export default class TypeHazardID extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }
  componentDidMount() {
    this.setState(this.props.state, () => {
      this.setState(
        {
          IncidentGUID: this.props.guid,
          IncidentNumber: this.props.number,
        },
        () => {
          this.props.update(this.state);
        }
      );
    });
  }

  handleUserInput = (event, key = ChangeTypes.NONE) => {
    const name = event.target.name;
    const value = event.target.value;
    switch (key) {
      case ChangeTypes.UPPER:
        this.setState({ [name]: value.toUpperCase().trim() }, () => {
          this.props.update(this.state);
        });
        break;
      case ChangeTypes.NONE:
        this.setState({ [name]: value.trim() }, () => {
          this.props.update(this.state);
        });
        break;
      case ChangeTypes.CHECK:
        this.setState({ [name]: value === "yes" }, () => {
          this.props.update(this.state);
        });
        break;
      case ChangeTypes.NOTES:
      default:
        this.setState({ [name]: value }, () => {
          this.props.update(this.state);
        });
        break;
    }
  };

  render() {
    return (
      <>
        <FormDropdown
          value={this.state.HazardIDType}
          onChange={this.handleUserInput}
          name="HazardIDType"
          title="Hazard ID Type"
          stackLabel={true}
          filter="Hazard ID"
          readOnly={this.props.readOnly}
          required={true}
          options={this.props.dropdowns.filter((x) => x.typeID === 7)}
          dropdownContentType="Type of Hazard ID"
        ></FormDropdown>
        <Label>
          <FontAwesomeIcon icon={faQuestionCircle}></FontAwesomeIcon>&nbsp;For
          more information on Hazard ID Type please click{" "}
          <a target="_blank" href="https://perkinelmer.sharepoint.com/:w:/r/sites/EHS/_layouts/15/Doc.aspx?sourcedoc=%7B7FE24973-51C8-4BB7-80E7-0ACCC6EBC9BA%7D">
            <FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon>&nbsp;here
          </a>
        </Label>
        <Hr />
      </>
    );
  }
}
