import React from "react";
import styled from "styled-components";
import { Col, Row } from "reactstrap";
import { GetUserInformation } from "../../services/ad";
import Center from "../atoms/center";
import Loading from "../atoms/loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faPerson,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import logo from "../../images/perkinelmer.png";

const UserPhoto = styled.img`
  width: 60px;
  min-height: 60px;
  height: auto;
`;
const UserPhotoContainer = styled.div`
  width: 60px;
  margin-bottom: 0;
  height: 60px;
  border-radius: 32px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border: 1px solid #5d5d5d;
  overflow: hidden;
`;
const Name = styled.span`
  font-size: 18px;
  font-weight: bold;
  color: #5d5d5d;
`;

const Container = styled.div`
  text-align: left;
  font-size: 14px;
  line-height: 18px;
  padding: 9px;
  position: relative;
  border-radius: 10px;
  display: inline-block;
  border: 1px solid #d7d7d7;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  background-color: white;
  margin-bottom: 15px;
  min-width: 380px;
  @media screen and (max-width: 768px) {
    min-width: 100%;
  }
  @media screen and (max-width: 500px) {
    font-size: 12px;
    min-width: 100%;
  }
`;

const RemoveIcon = styled(FontAwesomeIcon)`
  position: absolute;
  right: 10px;
  bottom: 11px;
  font-size: 16px;
  cursor: pointer;
`;

export default class UserCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Photo: "",
      loading: true,
    };
  }

  componentDidMount() {
    if (this.props.user !== undefined) {
      GetUserInformation(this.props.user).then((result) => {
        this.setState({ ...result, loading: false });
      });
    }
  }
  componentWillReceiveProps(newProps) {
    if (newProps.user !== this.props.user && this.props.user !== undefined) {
      GetUserInformation(this.props.user).then((result) => {
        this.setState({ ...result, loading: false });
      });
    }
  }

  render() {
    return (
      <Center {...this.props}>
        <Container>
          {this.state.loading && <Loading size="md"></Loading>}
          {!this.state.loading && (
            <Row>
              <Col style={{ maxWidth: "90px" }}>
                <UserPhotoContainer>
                  {this.state.Photo && (
                    <UserPhoto src={this.state.Photo}></UserPhoto>
                  )}
                  {!this.state.Photo && <UserPhoto src={logo}></UserPhoto>}
                </UserPhotoContainer>
              </Col>
              {this.state.Name && (
                <Col style={{ paddingLeft: "0" }}>
                  <Name>{this.state.Name}</Name>
                  <br />
                  <i>{this.state.JobTitle}</i>
                  <br />
                  {this.state.Department}&nbsp;-&nbsp;
                  {this.state.OfficeLocation}
                  <br />
                  <span
                    style={{
                      textTransform: "lowercase",
                      fontWeight: "bold",
                      color: "#112d8e",
                    }}
                  >
                    {this.state.Email}
                  </span>
                  <FontAwesomeIcon
                    style={{
                      color: "green",
                      position: "absolute",
                      right: "10px",
                      top: "10px",
                    }}
                    icon={faCheckCircle}
                  ></FontAwesomeIcon>
                </Col>
              )}
              {!this.state.Name && (
                <Col style={{ paddingLeft: "0" }}>
                  <Name>
                    {!this.props.guest?.Name && <>User Not Found</>}
                    {this.props.guest?.Name}&nbsp;<i>(Guest)</i>
                  </Name>
                  <br />
                  <span
                    style={{
                      textTransform: "lowercase",
                      fontWeight: "bold",
                      color: "#112d8e",
                    }}
                  >
                    {this.props.guest?.Email}
                  </span>
                  <FontAwesomeIcon
                    style={{
                      color: "#112d8e",
                      position: "absolute",
                      right: "10px",
                      top: "10px",
                    }}
                    icon={faPerson}
                  ></FontAwesomeIcon>
                </Col>
              )}
            </Row>
          )}
          {this.props.remove && !this.props.readOnly && (
            <RemoveIcon onClick={this.props.remove} icon={faTrash}></RemoveIcon>
          )}
        </Container>
      </Center>
    );
  }
}
