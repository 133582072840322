import React from "react";
import Label from "../atoms/label";
import styled from "styled-components";
import Input from "../atoms/input";
import { Col, Row } from "reactstrap";
import { toast } from "react-toastify";
import Required from "./required";

const StyledContainer = styled.div`
  margin-bottom: 5px;
`;
const StyledLabel = styled(Label)`
  @media screen and (max-width: 993px) {
    float: left;
  }
  @media (min-width: 576px) {
    height: 21px;
    margin-top: 5px;
  }
`;

export default class FormField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      invalid: props.invalid,
      min: this.props.min ? this.props.min : this.props.minN,
      max: this.props.max ? this.props.max : this.props.maxN,
      offset:
        this.props.min ||
        this.props.max ||
        this.props.maxN ||
        this.props.minN ||
        this.props.spec
          ? 5
          : 0,
      unit: this.props.unit ? this.props.unit : "",
    };
  }
  check = (event) => {
    if (this.state.max || this.state.min) {
      var val = parseFloat(event.target.value);
      if (this.state.max) {
        if (val > this.state.max) {
          toast.error(
            this.props.title +
              " cannot exceed " +
              Math.round(this.state.max) +
              this.state.unit
          );
        }
      }
      if (this.state.min) {
        if (val < this.state.min) {
          toast.error(
            this.props.title +
              " must be greater than " +
              this.state.min +
              this.state.unit
          );
        }
      }
      this.setState({ invalid: val > this.state.max || val < this.state.min });
    }
  };

  render() {
    var title = this.props.title;
    if (title.props) {
      title = title.props.children;
    }
    return (
      <StyledContainer>
        <Row>
          {!this.props.noLabel && (
            <Col
              xs={12 - this.state.offset}
              sm={12 - this.state.offset}
              md={
                this.props.noLabel
                  ? 12 - this.state.offset
                  : this.props.smaller
                  ? 3
                  : 2
              }
              style={{
                textAlign: this.props.align ? this.props.align : "left",
              }}
            >
              <StyledLabel>
                {this.props.title}
                {this.props.required ? <Required></Required> : ""}
              </StyledLabel>
            </Col>
          )}
          <Col
            xs="12"
            sm="12"
            md={
              this.props.noLabel
                ? 12 - this.state.offset
                : this.props.smaller
                ? 9 - this.state.offset
                : 10 - this.state.offset
            }
          >
            <Input
              step="any"
              placeholder={"Enter " + title}
              {...this.props}
              title={title}
              onBlur={this.props.onBlur ? this.props.onBlur : this.check}
              invalid={this.state.invalid}
            ></Input>
          </Col>
        </Row>
      </StyledContainer>
    );
  }
}
